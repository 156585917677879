@tailwind base;
@tailwind components;
@tailwind utilities;

/* elephant outline font */
@font-face {
  font-family: 'Elephant';
  src: local('Elephant'), url('./fonts/Elephant-Outline.otf') format('otf'),
    url('./fonts/Elephant-Outline.woff') format('woff'),
    url('./fonts/Elephant-Outline.svg') format('svg'),
    url('./fonts/Elephant-Outline.ttf') format('ttf');
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  src: url('./fonts/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato'),
    url('./fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/lato-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/lato-v23-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/lato-v23-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/lato-v23-latin-regular.svg#Lato')
      format('svg'); /* Legacy iOS */
}

@layer base {
  body {
    @apply font-[Lato];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
  background-image: linear-gradient(to bottom #030712, #0d223b);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-div:hover {
  background-image: linear-gradient(to bottom #070e21, #0e2f57);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
